import React from "react"

import Layout from "../../components/shared/Layout/layout"
import SEO from "../../components/seo"
import Documents from "../../components/Documents/Documents"
import Container from "../../components/shared/Layout/components/Container/container"
import styled from "styled-components"
import { Section } from "../../components/shared/Layout/components/Section/Section"
import { PageHeader } from "../../components/shared/PageHeader/PageHeader"

const DocumentsSection = styled(Section)``

const DocumentsPage = () => (
  <Layout fluid={true}>
    <SEO
      title="Dokumenty na stiahnutie"
      description="Stiahnite si potrebné dokumenty."
    />
    <DocumentsSection>
      <Container fluid={false}>
        <PageHeader header={"Dokumenty na stiahnutie"} />
        <Documents documents={[]} />
      </Container>
    </DocumentsSection>
  </Layout>
)

export default DocumentsPage
