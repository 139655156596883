import Grid from "@material-ui/core/Grid"
import React from "react"
import { HiDocumentDownload } from "react-icons/hi"
import styled from "styled-components"
import { muiTheme } from "../../../../lib/theme"
import Card from "../../../shared/Card"

interface Props {
  name: string
  link: string
}

const InnerDocumentItem = styled.a<{}>`
  display: flex;
  width: 100%;
  cursor: pointer;
  margin-bottom: 40px;
  transition: 350ms all ease-in-out;
  flex-wrap: nowrap;

  &:hover {
    transform: scale(1.02);
  }

  ${muiTheme.breakpoints.down("md")} {
    margin-bottom: 20px;
  }
`
const ItemLabel = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  align-items: center;
  max-width: 600px;
  padding: 30px;
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 23px;
    text-align: center;
    color: ${({ theme }) => theme.color.primary};
  }

  ${muiTheme.breakpoints.down("md")} {
    padding: 20px;

    span {
      font-size: 16px;
      text-align: left;
    }
  }
`

const ItemDownload = styled.div`
  height: 100%;
  width: 80px;
  min-width: 80px;

  background: ${({ theme }) => theme.color.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: white;

  ${muiTheme.breakpoints.down("md")} {
    font-size: 30px;
  }
`

const DocumentItem: React.FC<Props> = (props) => {
  return (
    <InnerDocumentItem target="_blank" href={props.link} rel="noopener noreferrer">
      <Card
        style={{
          display: "flex",
          flex: "1 1 auto",
          padding: "0",
        }}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="stretch"
          // spacing={0}
          style={{ flexWrap: "nowrap" }}
        >
          <ItemLabel>
            <span>{props.name}</span>
          </ItemLabel>
          <ItemDownload>
            <HiDocumentDownload />
          </ItemDownload>
        </Grid>
      </Card>
    </InnerDocumentItem>
  )
}

export default DocumentItem
