import React from "react"
import DocumentItem from "./components/DocumentItem/DocumentItem"
import Grid from "@material-ui/core/Grid"
import { useDocuments } from "./hooks/useDocuments"

interface Props {
  documents: Document[]
}

// ------------------------------> <3 ;)

const Documents: React.FC<Props> = () => {
  const documents = useDocuments()
  return (
    <Grid
      container
      style={{
        maxWidth: "800px",
        width: "100%",
        margin: "auto",
        marginBottom: "40px",
      }}
      direction="column"
      justify="space-between"
      alignItems="stretch"
    >
      {documents.map((document, index) => (
        <div
          data-sal-duration="700"
          data-sal="slide-up"
          data-sal-delay={index * 50}
          data-sal-easing="ease"
          key={document.name}
        >
          <DocumentItem name={document.name} link={document.link} />
        </div>
      ))}
    </Grid>
  )
}

export default Documents
