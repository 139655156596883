import React, { ReactNode } from "react"
import styled from "styled-components"

export interface PageHeaderProps {
  className?: string
  children?: ReactNode
  header: ReactNode
  subheader: ReactNode
}

const PageHeaderInner = (props: PageHeaderProps) => {
  const { header, subheader = "Autoškola Hron", children } = props
  return (
    <div className={"mb-8 md:mb-20"}>
      <span className="upTitle">{subheader}</span>
      <h1>{header}</h1>
      {children}
    </div>
  )
}

export const PageHeader = styled(PageHeaderInner)``
