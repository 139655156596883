import Prihlaska from "./../dokumenty/Prihlaska_do_kurzu_Autoskola_Hron_sro_farebna.pdf"
import ZiadostVO from "./../dokumenty/Ziadost_o_udelenie_vodicskeho_opravnenia_Autoskola_Hron_sro.pdf"
import ZiadostEV17 from "./../dokumenty/Ziadost_o_zapis_do_evidencie_vodicov_17_rocnych_Autoskola_Hron_sro.pdf"
import KartaPrvejPomoci from "./../dokumenty/Karta-prvej-pomoci.pdf"
import GDPR from "./../dokumenty/GDPR_Autoskola_Hron_sro.pdf"
import InformacnaPovinnost from "./../dokumenty/Informacna_povinnost_na_web_Autoskola_Hron.pdf"
import ReklamacnyPoriadok from "./../dokumenty/Reklamacny_poriadok_Autoskola_Hron_sro.pdf"

export interface Document {
  link: string
  name: string
}

export const useDocuments = (): Document[] => {
  return [
    {
      name: "Prihláška do kurzu",
      link: Prihlaska,
    },
    {
      name: "Žiadosť o udelenie vodičského oprávnenia",
      link: ZiadostVO,
    },
    {
      name: "Žiadosť o zápis do evidencie 17 ročných vodičov",
      link: ZiadostEV17,
    },
    {
      name: "Reklamačný poriadok",
      link: ReklamacnyPoriadok,
    },

    {
      name: "Informačná povinnosť prevádzkovateľa",
      link: InformacnaPovinnost,
    },
    {
      name: "GDPR - Ochrana osobných údajov",
      link: GDPR,
    },
    {
      name: "Karta prvej pomoci",
      link: KartaPrvejPomoci,
    },
  ]
}
